.BaseTable__row:hover,
.BaseTable__row--hovered {
  background-color: var(--color-light-bg) !important;
}

.BaseTable__header-row {
  background-color: white !important;
}

.BaseTable__header-cell--sortable:hover {
  background-color: var(--color-light-bg);
}

.BaseTable__header-cell .BaseTable__sort-indicator {
  display: block;
}
/* .BaseTable__table ::-webkit-scrollbar {
    height: 0px;
} */

.BaseTable__sort-indicator {
  font-size: 0;
  position: relative;
  margin: 0 0 0 5px;
}
.BaseTable__sort-indicator:before,
.BaseTable__sort-indicator:after {
  background: #8c8c8c;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  top: 7px;
  width: 6px;
}
.BaseTable__sort-indicator:before {
  border-radius: 5px 0 0 5px;
  left: 6px;
  transform: rotate(45deg);
}
.BaseTable__sort-indicator:after {
  border-radius: 0 5px 5px 0;
  left: 3px;
  transform: rotate(-45deg);
}
.BaseTable__sort-indicator--descending:before {
  left: 3px;
}
.BaseTable__sort-indicator--descending:after {
  left: 6px;
}

.BaseTable__row:hover .primary-highlight {
  color: var(--color-primary);
}

.dashboard-container {
  height: calc(100vh - 235px);
}

.BaseTable__table-main{
  outline: none !important;
}

.BaseTable{
  box-shadow: none !important;
}