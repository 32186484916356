@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
  .create-success-container {
    @apply mx-4 my-3 flex flex-col items-start justify-center sm:mx-auto sm:w-4/5 md:w-7/12 lg:w-1/3;
  }

  .secondary-btn {
    border: 1px solid var(--color-lightgray);
    @apply my-1 w-full cursor-pointer rounded-md bg-white p-4 text-base text-gray-500 shadow hover:bg-gray-100;
  }

  .w-35-ch {
    max-width: 35ch;
  }
  .w-16-ch {
    max-width: 16ch;
  }

  .w-12-ch {
    width: 12ch;
  }

  .cursor-grabbing {
    cursor: grabbing;
  }

  .group-milestone:hover .group-milestone-hover\:visible {
    visibility: visible;
  }

  .group-action:hover .group-action-hover-color {
    color: var(--color-primary);
  }

  .group-assignee:hover .group-assignee-hover\:visible {
    visibility: visible;
  }

  .hidden-item {
    display: none;
  }

  .z-40 {
    z-index: 40 !important;
  }

  .hidden-item-parent:hover .hidden-item,
  .clear-assignee-parent:hover .clear-assignee-btn {
    display: block;
  }

  .hidden-item-parent:hover + .hidden-item {
    display: block;
  }

  .progress-ring__circle {
    transition: 0.85s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .task-row-container {
    width: calc(100% - 54px);
  }
  .task-milestone-row-container {
    width: calc(100% - 31px);
  }

  .title-input-grid {
    grid-template-columns: 1fr 56px 40px;
  }

  .title-input-password-grid {
    grid-template-columns: 1fr 40px 40px;
  }

  .title-input-read-only {
    grid-template-columns: minmax(150px, max-content);
  }

  .bottom-divider {
    @apply border-0 border-b border-solid border-mid-100;
  }

  .max-w-24ch {
    max-width: 24ch;
  }

  .max-w-16ch {
    max-width: 16ch;
  }

  .bg-black-80 {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .plan-view-container {
    min-height: calc(100vh - 56px);
  }

  .analytics-view-container {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }

  .notification-height-without-header {
    height: calc(100vh - 164px);
  }

  #infinite-loader-outline {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: 0;
    -webkit-animation: anim 1.6s linear infinite;
    animation: anim 1.6s linear infinite;
  }

  @-webkit-keyframes anim {
    12.5% {
      stroke-dasharray: 33.98873px, 242.77666px;
      stroke-dashoffset: -26.70543px;
    }
    43.75% {
      stroke-dasharray: 84.97183px, 242.77666px;
      stroke-dashoffset: -84.97183px;
    }
    100% {
      stroke-dasharray: 2.42777px, 242.77666px;
      stroke-dashoffset: -240.34889px;
    }
  }

  @keyframes anim {
    12.5% {
      stroke-dasharray: 33.98873px, 242.77666px;
      stroke-dashoffset: -26.70543px;
    }
    43.75% {
      stroke-dasharray: 84.97183px, 242.77666px;
      stroke-dashoffset: -84.97183px;
    }
    100% {
      stroke-dasharray: 2.42777px, 242.77666px;
      stroke-dashoffset: -240.34889px;
    }
  }

  /* .ba-influencer-edges {
    stroke-width: 1.5px !important;
  } */
  .ba-influencer-transparent-edge:hover + .ba-influencer-edges,
  .ba-influencer-edges:hover {
    stroke-width: 2.5px !important;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .settings-menu-item:hover {
    background-color: #edeefb;
  }

  @media (max-width: 1100px) {
    .msp-header-title-container {
      max-width: 50ch;
    }
  }

  .svg-icon {
    display: inline-block;
  }

  .svg-icon .top-icon {
    display: none;
  }
  .svg-icon .bottom-icon {
    display: inline;
  }
  .svg-icon:hover .top-icon {
    display: inline;
  }
  .svg-icon:hover .bottom-icon {
    display: none;
  }

  [data-radix-popper-content-wrapper] {
    z-index: 50 !important;
  }

  [data-radix-popper-content-wrapper]:has([id='drawer-overlay']) {
    z-index: 1500 !important;
  }

  [data-highlighted] {
    background-color: var(--color-primary-light-bg);
  }
  .empty-canvas {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2359758AFF' stroke-width='2' stroke-dasharray='8%2c12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  }

  .max-width-10-ch {
    max-width: 10ch;
  }

  .max-width-14-ch {
    max-width: 14ch;
  }

  .ba-transition-50 {
    @apply transition-all;
    transition-duration: 50ms;
  }

  .min-h-7 {
    min-height: 28px;
  }

  .ellipsis-2-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ba-lite-lexical-editor-content p,
  .ba-lite-lexical-editor-content h1,
  .ba-lite-lexical-editor-content h2,
  .ba-lite-lexical-editor-content h3,
  .ba-lite-lexical-editor-content h4,
  .ba-lite-lexical-editor-content h5,
  .ba-lite-lexical-editor-content h6,
  .ba-lite-lexical-editor-content li,
  .ba-lite-lexical-editor-content ul {
    margin: 0 !important;
  }

  .ba-lite-lexical-editor-content ::selection {
    background: var(--color-primary-light-bg) !important;
    color: inherit !important;
  }

  .multicolor-wheel {
    background: conic-gradient(
        from 180deg at 50% 50%,
        #e03e1a 0deg,
        #f79722 67.5deg,
        #198f51 163.12deg,
        #0c8ce9 226.87deg,
        #8a38f5 281.25deg,
        #e03e1a 360deg
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        #ffffff 0%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
  }

  .ba-rmap-contact-table .BaseTable__row-cell {
    padding: 8px 0 !important;
  }
}
