:root {
  --color-primary: #485cf0;
  --color-primary-100: #edeffe;
  --color-primary-200: #b6bef9;
  --color-primary-300: #919df6;
  --color-secondary: #2ad2f4;
  --color-warning: #fd4954;
  --color-light-bg: #eff2f4;
  --color-primary-light-bg: rgba(72, 92, 240, 0.1);
  --color-light-bg: #f8f9fb;
  --color-mid: #59758a;
  --color-neutral: #1b252f;
  --color-neutral-200: #a4a8ac;
  --color-warning-10: rgba(255, 106, 9, 0.1);
  --color-warning-500: #ec9750;
  --color-warning-600: #dc514d;
  --color-warning-700: #ee224e;
  --color-warning-700: #ee224e;
  --color-yellow-700: #f6ac1b;
  --color-linkedin: #0077b5;
  --color-linkedin-light: #0e8def;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 16px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
} */
#launcher-frame {
  max-width: 112px !important;
  height: 45px !important;
}

.d-hotline.h-btn {
  width: 48px !important;
  height: 60px !important;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0px !important;
  padding: 0px !important;
  scrollbar-width: thin;
  width: 100vw !important;
  font-family: 'Lato', sans-serif, 'Helvetica Neue', Helvetica, Arial !important;
}

.view-only {
  pointer-events: none;
  cursor: default;
}

.height-without-header {
  height: calc(100vh - 50px);
}

.react-pdf__message.react-pdf__message--error {
  color: white;
}

.mention-input__suggestions {
  z-index: 99999999 !important;
}

.react-flow__attribution a {
  display: none !important;
}

.react-flow__controls-interactive {
  display: none !important;
}

.react-flow__controls {
  top: 24px !important;
  right: 12px !important;
  bottom: unset !important;
  left: unset !important;
  position: absolute;
  z-index: 5;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08) !important;
}

img {
  box-sizing: border-box;
}

svg {
  box-sizing: content-box;
}

/* {
  box-sizing: unset !important;
} */

.meddic-notes fieldset {
  visibility: hidden;
}

.react-flow__edge.animated svg path {
  animation: none !important;
  /* stroke-dashoffset: 0 !important; */
  stroke-dasharray: 0 !important;
}

.task-due-date-calendar-icon {
  color: var(--color-mid);
  height: 26px;
  width: 26px;
  display: grid;
  place-items: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2359758AFF' stroke-width='2' stroke-dasharray='1%2c 3' stroke-dashoffset='1' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.ant-picker-input:hover .task-due-date-calendar-icon {
  color: white;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-image: unset;
}

.ant-picker-input:hover .task-due-date-calendar-icon > svg {
  color: white;
}

.max-width-10-ch {
  max-width: 10ch;
}

.max-width-14-ch {
  max-width: 14ch;
}

.max-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.max-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-4-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.max-width-16 {
  max-width: 64px;
}

.max-width-80 {
  max-width: 320px;
}
