.ba-search-select-container {
  position: relative;
}
.ba-search-select-container::before {
  content: attr(data-name);
  position: absolute;
  margin-left: 4px;
  font-size: 13px;
  line-height: 1.4;
  pointer-events: none;
}