.ant-picker-panel-container {
  border-radius: 8px !important;
  box-shadow: 0px 4px 14px 6px rgba(0, 0, 0, 0.03) !important;
  border: 1px solid #eaeaea !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 15px !important;
}

.milestone-error {
  outline: 1px solid var(--color-warning-700) !important;
}

.ant-picker-input > input {
  color: var(--color-mid) !important;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}

.ant-picker.removed-task > .ant-picker-input > input {
  visibility: hidden;
}

.ant-picker.create-msp-plan > .ant-picker-input > input {
  height: 2.125rem !important;
  width: 12.5rem !important;
}

/* .plan-view-due-date >.ant-picker-input>input{
   text-align: center !important;
} */

.start-date-warning > .ant-picker-input:first-child > input,
.end-date-warning > div:nth-child(3) > input {
  color: var(--color-warning-600) !important;
}

.ant-picker-input > input:hover,
.start-date-warning > .ant-picker-input:first-child > input:hover,
.end-date-warning > div:nth-child(3) > input:hover {
  color: var(--color-primary) !important;
}

.ant-picker.ant-picker-disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.ant-picker.create-msp-plan.ant-picker-focused {
  outline: 2px solid white !important ;
  border-color: var(--color-primary) !important;
}

.ant-picker.create-msp-plan:hover {
  border-color: #d9d9d9;
}
.ant-picker.create-milestone:hover {
  border-color: var(--color-primary) !important;
}
.ant-picker.create-milestone-date-error {
  border-bottom-color: var(--color-warning-600) !important;
}

.ant-picker.create-msp-plan,
.ant-picker.create-msp-plan.ant-picker-focused,
.ant-picker.create-msp-plan:hover {
  border-radius: 0.375rem !important;
}

.ant-picker-header-view {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
}

.ant-picker-header-view > button:hover {
  color: var(--color-primary) !important;
}

.ant-picker-active-bar {
  background-color: var(--color-primary) !important;
  min-width: 2.8rem;
  padding-left: 4%;
  margin-left: 30px !important;
}
.plan-view .ant-picker-active-bar {
  min-width: 2.2rem !important;
  margin-left: 5px !important;
  max-width: 2.8rem !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--color-primary) !important;
  border-radius: 7px !important;
  font-weight: 700 !important;
}

.ant-picker-cell-in-view .ant-picker-cell-inner {
  width: auto !important;
  height: auto !important;
  min-width: 36px !important;
  min-height: 34px !important;
  padding-top: 5px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #edeffe !important;
  min-width: 36px !important;
  min-height: 34px !important;
}

.antd-range-picker td,
.antd-single-picker td {
  width: auto !important;
  height: auto !important;
  min-width: 36px !important;
  min-height: 34px !important;
  max-width: fit-content;
  padding: 0px !important;
  border-radius: 7px !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  /* margin-right: 0.5rem; */
  order: -1;
}

.ant-picker .ant-picker-input span.ant-picker-clear,
.ant-picker.ant-picker-range span.ant-picker-clear {
  background-color: transparent;
}
.ant-picker .ant-picker-input span.ant-picker-clear:hover,
.ant-picker.ant-picker-range span.ant-picker-clear {
  color: var(--color-warning-600);
  background-color: transparent;
}

.ant-picker-header,
.ant-picker-panel-container .ant-picker-panel {
  border-bottom: none !important;
}

.ant-picker-panels:first-child .ant-picker-header-super-prev-btn {
  padding-right: 25px !important;
}

.ant-picker-panels > div:nth-child(2) .ant-picker-header-super-next-btn {
  padding-left: 25px !important;
}

.antd-range-picker tr,
.antd-single-picker tr {
  height: 36px !important;
  padding: 0px !important;
}

/* Border changes */

.ant-picker-date-panel
  .ant-picker-cell-in-range:hover
  .ant-picker-cell-range-hover-start {
  background-color: #fd4954 !important;
}

.ant-picker-date-panel .ant-picker-cell-range-hover::after {
  border-top: 1px dashed var(--color-primary) !important;
  border-bottom: 1px dashed var(--color-primary) !important;
  height: calc(100% - 2px) !important;
}

.ant-picker-date-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-date-panel .ant-picker-cell-range-hover-edge-start::after {
  border-top: 1px dashed var(--color-primary) !important;
  border-left: 1px dashed var(--color-primary) !important;
  border-bottom: 1px dashed var(--color-primary) !important;
  height: calc(100% - 2px) !important;
}

.ant-picker-date-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-date-panel .ant-picker-cell-range-hover-edge-end::after {
  border-top: 1px dashed var(--color-primary) !important;
  border-right: 1px dashed var(--color-primary) !important;
  border-bottom: 1px dashed var(--color-primary) !important;
  height: calc(100% - 2px) !important;
}

.ant-picker-date-panel .ant-picker-cell-range-hover:first-child:after {
  border-left: 1px dashed var(--color-primary) !important;
}

.ant-picker-date-panel .ant-picker-cell-range-hover:last-child:after {
  border-right: 1px dashed var(--color-primary) !important;
}

/* Range hover color */

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:hover::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:hover::before {
  background: #485cf088 !important;
}

.ant-picker-date-panel .ant-picker-cell-inner::after,
.ant-picker-cell-inner::before {
  display: none;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner {
  border: var(--color-primary) 1px solid;
  border-radius: 0px !important;
  margin: 0 2px;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view:not(.ant-picker-cell-selected).ant-picker-cell-today
  .ant-picker-cell-inner:hover {
  background-color: white !important;
}

/* Month Picker */

.ant-picker-month-panel
  .ant-picker-cell-in-view:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)
  > .ant-picker-cell-inner:hover {
  outline: 1px dashed var(--color-primary) !important;
  background: none !important;
}

.ant-picker-month-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-month-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-month-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  min-width: min-content !important;
}

.ant-picker-month-panel .ant-picker-cell:hover,
.ant-picker-month-panel .ant-picker-cell-range-hover::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-edge-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-edge-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover:first-child:after,
.ant-picker-month-panel .ant-picker-cell-range-hover:last-child:after {
  border: none !important;
}

/* Year Picker */

.ant-picker-year-panel
  .ant-picker-cell-in-view:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)
  > .ant-picker-cell-inner:hover {
  outline: 1px dashed var(--color-primary) !important;
  background: none !important;
}

.ant-picker-year-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-year-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-year-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  min-width: min-content !important;
}

.ant-picker-year-panel .ant-picker-cell:hover,
.ant-picker-year-panel .ant-picker-cell-range-hover::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-edge-start::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-year-panel .ant-picker-cell-range-hover-edge-end::after,
.ant-picker-year-panel .ant-picker-cell-range-hover:first-child:after,
.ant-picker-year-panel .ant-picker-cell-range-hover:last-child:after {
  border: none !important;
}

/* Decade Picker (2020-2030) */

.ant-picker-decade-panel
  .ant-picker-cell-in-view:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)
  > .ant-picker-cell-inner:hover {
  outline: 1px dashed var(--color-primary) !important;
  background: none !important;
}

.ant-picker-decade-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-decade-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-decade-panel
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  min-width: min-content !important;
}

.ant-picker-decade-panel .ant-picker-cell:hover,
.ant-picker-decade-panel .ant-picker-cell-range-hover::after,
.ant-picker-decade-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-decade-panel .ant-picker-cell-range-hover-edge-start::after,
.ant-picker-decade-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-decade-panel .ant-picker-cell-range-hover-edge-end::after,
.ant-picker-decade-panel .ant-picker-cell-range-hover:first-child:after,
.ant-picker-decade-panel .ant-picker-cell-range-hover:last-child:after {
  border: none !important;
}
.ant-picker-date-panel + .ant-picker-footer > .ant-picker-today-btn {
  color: var(--color-primary) !important;
}
.ant-picker-date-panel + .ant-picker-footer {
  max-height: 30px !important;
  padding-top: 3px !important;
  color: var(--color-primary) !important;
}

.table-container > .ant-picker-panel-container {
  position: fixed !important;
}

.overdue-task > .ant-picker-input > input,
.overdue-task {
  color: var(--color-warning-600) !important;
}
.upcoming-due-task > .ant-picker-input > input,
.upcoming-due-task {
  color: var(--color-warning-500) !important;
}
.upcoming-due-task > .ant-picker-input > input::placeholder,
.upcoming-due-task {
  color: var(--color-warning-500) !important;
}

.antd-date-picker {
  padding: 0px 6px !important;
}

.antd-date-picker.all-task-table {
  padding: 0px !important;
}

.upcoming-due-task > .ant-picker-input > input:hover::placeholder,
.overdue-task > .ant-picker-input > input:hover::placeholder {
  color: var(--color-primary) !important;
}
